import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import FooterStyles from "./footer.module.scss"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { faFacebook } from "@fortawesome/free-brands-svg-icons"
import { faWeixin } from "@fortawesome/free-brands-svg-icons"
import { faHeart } from "@fortawesome/free-solid-svg-icons"

library.add(faWhatsapp)
library.add(faFacebook)
library.add(faWeixin)
library.add(faHeart)

const Footer = () => {
  return (
    <footer className={FooterStyles.footer}>
      <Container>
        <Row className={FooterStyles.rowOne} noGutters>
          <Col lg={4} md={12}>
            <Row className={FooterStyles.company}>
              <img
                src="/img/LogoFoot.png"
                alt="Million Production & Promotion Co."
              />
              <p>© 2019 Million Production & Promotion Co</p>
            </Row>
          </Col>
          <Col lg={4} md={12}>
            <Row className={FooterStyles.rowOne}>
              <p>
                Room 3503, Central Plaza, <br />
                18 Harbour Road, Wan Chai, Hong Kong
                <br />
                T: +852 2593 1200 | F: +852 2593 1222
              </p>
            </Row>
          </Col>
          <Col lg={4} md={12}>
            <Row className={FooterStyles.icons}>
            <a
                href="https://api.whatsapp.com/send?phone=852xxxxxxxx&text=I want to enquire about your services"
                target="_blank"
                rel="noopener noreferrer"
              ><FontAwesomeIcon icon={["fab", "whatsapp"]} /></a>
              <a
                href="https://www.facebook.com/pages/Million-Production-Promotion-Co/384684814887927/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <FontAwesomeIcon icon={["fab", "weixin"]} />
            </Row>
          </Col>
        </Row>
        <Row className={FooterStyles.credit}>
          <p>
            Made with{" "}
            <FontAwesomeIcon className={FooterStyles.loveIcon} icon="heart" />{" "}
            by{" "}
            <a
              href="https://www.cohort6productions.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cohort 6 Productions
            </a>
          </p>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
