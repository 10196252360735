import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

import TestimonialStyles from "./testimonial.module.scss"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons"

library.add(faQuoteLeft)
library.add(faQuoteRight)

const TestimonialsCards = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card1}
            >
              <Card.Body style={{ padding: "20px" }}>
                <Card.Text>
                  <FontAwesomeIcon icon="quote-left" /> &emsp; The event last
                  night was a huge success, and the feedback we’ve got thus far
                  has been very positive. The atmosphere, in particular, was one
                  of the best if not the best for staff dinners in the past few
                  years. All of our ExCo members were pleased and their guests
                  had a great evening too.&emsp;
                  <FontAwesomeIcon icon="quote-right" />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                Associate Director,
                <br />
                <b>AIA Group</b>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card1}
            >
              <Card.Body>
                <Card.Text>
                  <FontAwesomeIcon icon="quote-left" />
                  &emsp; Thank you for your excellent job during our annual
                  dinner night. We were gratified by your professional and
                  cooperation attitude. It surely added to its success and made
                  it enjoyable to our staff! Once again, thank you for your
                  significant effort, we hope to have a chance to work with your
                  team again!&emsp;
                  <FontAwesomeIcon icon="quote-right" />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                Executive General Manager,
                <br />
                <b>Leighton Asia</b>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card2}
            >
              <Card.Body>
                <Card.Text>
                  <FontAwesomeIcon
                    icon="quote-left"
                    style={{ fontSize: "1rem" }}
                  />
                  &emsp; Thank you for your excellent job during our annual
                  dinner night. We were gratified by your professional and
                  cooperation attitude. It surely added to its success and made
                  it enjoyable to our staff! Once again, thank you for your
                  significant effort, we hope to have a chance to work with your
                  team again!&emsp;
                  <FontAwesomeIcon
                    icon="quote-right"
                    style={{ fontSize: "1rem" }}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                Annual Dinner Organizing Committee,
                <br />
                <b>Gucci</b>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card2}
            >
              <Card.Body>
                <Card.Text>
                  <FontAwesomeIcon
                    icon="quote-left"
                    style={{ fontSize: "1rem" }}
                  />
                  &emsp;I must send Million a heartfelt thanks for all their
                  hard work and efforts yesterday. The team were outstanding,
                  and everything ran so smoothly from start to finish. Would say
                  it was our best yet.&emsp;
                  <FontAwesomeIcon
                    icon="quote-right"
                    style={{ fontSize: "1rem" }}
                  />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                Managing Director,
                <br />
                <b>Dansinn Heavenly</b>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          {" "}
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card3}
            >
              <Card.Body>
                <Card.Text>
                  <FontAwesomeIcon icon="quote-left" /> &emsp; I want to thank
                  you all from the bottom of my heart for helping to create a
                  memorable event yesterday.I know that past few months and
                  weeks have been stressful and challenging, but I feel this was
                  one of the best events we have worked on together as a team. I
                  really appreciate all your efforts.&emsp;
                  <FontAwesomeIcon icon="quote-right" />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                140th Anniversary Charity Dance Organizing Committee,
                <br />
                <b>Po Leung Kuk</b>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Card
              style={{
                margin: "20px 20px",
                boxShadow: "0px 0px 20px grey",
                display: "flex",
                flexDirection: "column",
              }}
              className={TestimonialStyles.card3}
            >
              <Card.Body>
                <Card.Text>
                  <FontAwesomeIcon icon="quote-left" />
                  &emsp; I would like to thank you and the entire team at
                  MILLION for a wonderful series of events. We came together to
                  achieve our goals and the various teams were very impressed
                  with not only the set-up and LED but the overall performance
                  of the events. I would personally like to thank you for the
                  speedy changes we made to the foyer, the intelligence of your
                  technical crew to make all the videos-images-logos look
                  spectacular and purely running a clean program.&emsp;
                  <FontAwesomeIcon icon="quote-right" />
                </Card.Text>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                Director of International Events,
                <br />
                <b>MGM Resort International</b>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TestimonialsCards
