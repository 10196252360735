import React from "react"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Service = () => {
  return (
    <div className="serviceBox">
      <Container>
        <Row>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src="/img/conceptDesign.jpg" alt="Concept Design" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                从年度晚宴到产品发布，我们可以帮助您设计和创建活动
                帮助您传递预期的信息
              </p>
            </div>

            <h3>概念设计</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/stageVenueDecoration.jpg"
                alt="Stage and Venue Decoration"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                {" "}
                <br />
                通过我们为您量身定制的设计方案布置您的场地，
                为您的賓客提供难忘的体验
              </p>
            </div>

            <h3>舞台和场地布置</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img src="/img/programLogistics.jpg" alt="Program Logistics" />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                规划与执行同等重要， 我们在行业中的丰富经验
                将确保活动协调良好地完成
              </p>
            </div>

            <h3>活动流程</h3>
          </Col>
          <Col md={12} className="serviceBoxContainer">
            <div className="serviceBoxImage">
              <img
                src="/img/audiovisualEffects.jpg"
                alt="audiovisual Effect"
              />
            </div>
            <div className="serviceBoxOverlay">
              <p>
                从灯光，音响，视频到舞台冷烟花，我们确保观众获得最佳的视觉享受
              </p>
            </div>

            <h3>视听方案</h3>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Service
