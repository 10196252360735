import React from "react"

import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import Modal from "react-bootstrap/Modal"

// import { library } from "@fortawesome/fontawesome-svg-core"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faEdit } from "@fortawesome/free-solid-svg-icons"
// library.add(faEdit)

class MyModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>公司简介</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            // paddingLeft: "0px",
          }}
        >
          {/* <Col xs={0} sm={0} lg={4}>
            <img
              className="historyPic"
              style={{ height: "auto", width: "100%" }}
              src="/img/download.png"
              alt=""
            />
          </Col>
          <Col xs={12} sm={12} lg={8}> */}
          <Col>
            <br />
            <p style={{ fontSize: "20px" }}>
              富高娱乐制作公司于1989年由从事多年制作，并深耕于演艺娱乐及推广行业，拥有丰富经验的麦润寿先生,
              BBS, MH 成立。
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              多年来，麦润寿先生带领团队以专业知识和经验，为本地及跨国企业提供全面的设计及顾问服务，并统筹举办各种活动。
            </p>
            <br />
            <p style={{ fontSize: "20px" }}>
              凭借麦润寿先生多年从事制作的丰富经验和活动策划领域中的广博知识，以及富有创意的团队，富高娱乐制作公司全力以赴，将为您提供活动策划的最佳建议和创意。
            </p>
          </Col>
        </Modal.Body>
      </Modal>
    )
  }
}

class CompanyHistoryModal extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = { modalShow: false }
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false })

    return (
      <ButtonToolbar style={{ paddingLeft: "10vw" }}>
        <Button
          onClick={() => this.setState({ modalShow: true })}
          style={{
            backgroundColor: "transparent",
            border: "solid white 1px",
            width: "100px",
            // borderRadius: "50%",
          }}
          // style={{
          //   backgroundColor: "transparent",
          //   border: "none",
          //   borderRadius: "50%",
          // }}
        >
          简介
          {/* <FontAwesomeIcon
            className="historyIcon"
            icon="edit"
            style={{ color: "white", fontSize: "100px" }}
          /> */}
        </Button>

        <MyModal show={this.state.modalShow} onHide={modalClose} />
      </ButtonToolbar>
    )
  }
}

export default CompanyHistoryModal
