import React from "react"

import Layout from "../components/layoutSC"
import Hero from "../components/heroSC"
import Company from "../components/companySC"
import WhatWeDo from "../components/whatWeDoSC"
import Service from "../components/serviceSC"
import Client from "../components/clientSC"
import Testimonial from "../components/testimonialSC"
import Portfolio from "../components/portfolioSC"
import ContactUs from "../components/contactUsSC"

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
       <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{
            // display: "block",
            marginTop: "-126px",
            width: "100%",
            height: "100px",
            color: "white",
            // transform: "rotate(180deg)",
          }}
          preserveAspectRatio="none"
          viewBox="0 0 200 100"
        >
          <path fill="currentColor" d="M0,0L200,100L0,100z" />
        </svg>
      <Company />
      <WhatWeDo />
      
      <Service />
      <Client />
      <Testimonial />      
      <Portfolio />
      <ContactUs />
    </Layout>
  )
}

export default IndexPage
