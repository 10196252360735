import React from "react"

const serviceAccordion = () => {
  return (
    <div className="serviceAccordion">
      <ul>
        <li>
          <div>
            <a href="#services">
              <h3>概念设计</h3>
              <h4>
                <br />
                从年度晚宴到产品发布，
                <br />
                我们可以帮助您设计和创建活动
                <br />
                帮助您传递预期的信息
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>舞台和场地布置</h3>
              <h4>
                <br />
                通过我们为您量身定制的
                <br />
                設計方案来布置您的场地，
                <br />
                为您的賓客提供难忘的体验
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>活动流程</h3>
              <h4>
                <br />
                规划与执行同等重要，
                <br />
                我们在行业中的丰富经验
                <br />
                将确保活动协调良好地完成
              </h4>
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="#services">
              <h3>视听方案</h3>
              <h4>
                <br />
                从灯光，音响，视频到
                <br />
                舞台冷烟花，我们确保
                <br />
                观众获得最佳的视觉享受
              </h4>
            </a>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default serviceAccordion
