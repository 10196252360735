import React from "react"

import Button from "react-bootstrap/Button"
import ButtonToolbar from "react-bootstrap/ButtonToolbar"
import Modal from "react-bootstrap/Modal"

// import { library } from "@fortawesome/fontawesome-svg-core"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faPlayCircle } from "@fortawesome/free-solid-svg-icons"
// library.add(faPlayCircle)

class MyVerticallyCenteredModal extends React.Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="whatWeDoModal"
        
      >
        <Modal.Body>
          <video controls autoPlay="autoplay" style={{ width: "100%" }}>
            <source src="../../video/million.mp4" type="video/mp4" />
            Your browser doesn't support HTML5 video tag.
          </video>
        </Modal.Body>
      </Modal>
    )
  }
}

class WhatWeDoModal extends React.Component {
  constructor(...args) {
    super(...args)
    this.state = { modalShow: false }
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false })

    return (
      <ButtonToolbar style={{paddingRight: "10vw"}}>
        <Button
          onClick={() => this.setState({ modalShow: true })}
          // style={{
          //   backgroundColor: "transparent",
          //   border: "none",
          //   borderRadius: "50%",
          // }}
          style={{
            backgroundColor: "transparent",
            border: "solid white 1px",
            width: "100px"
          }}
        >
          {/* <FontAwesomeIcon
            className='historyIcon'
            icon="play-circle"
            style={{ color: "white", fontSize: "100px" }}
          /> */}
    影片
        </Button>

        <MyVerticallyCenteredModal
          show={this.state.modalShow}
          onHide={modalClose}
        />
      </ButtonToolbar>
    )
  }
}

export default WhatWeDoModal
