import React from "react"

// import smooth scrolling
import Scrollchor from "react-scrollchor"

import Container from "react-bootstrap/Container"
// import Row from "react-bootstrap/Row"
// import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import heroStyles from "./hero.module.scss"

const Hero = () => {
  return (
    <div className={heroStyles.hero}>
      <Container>
        <div className={heroStyles.heroContent}>
          <h1>
            我们创造 <br />
            令人难忘的活动           
          </h1>
          <div id="hero-description">
            <h3>
              我们帮助大型企業和團體
              <br />
              举办独特的活动，为他们   
              <br />
              的客人创造一个难忘的体验。
            </h3>
          </div>
          <Scrollchor to="#services" animate={{ offset: -100 }}>
            <Button
              style={{
                boxShadow: "0 0 15px black",
                backgroundColor: "#413C58",
              }}
            >
              看看我们能为您做到什么？
            </Button>
          </Scrollchor>
        </div>
      </Container>
    </div>
  )
}

export default Hero
