import React, { Component } from "react"
import Slider from "react-slick"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

export default class ClientCarousel extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 2000,
      speed: 2000,
      slidesToShow: 5,
      slidesToScroll: 1,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <div id="clientCarousel" style={{ maxWidth: "97%" }}>
        <Slider {...settings}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/AIA.png"
              alt="AIA"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src="/img/clientLogos/AXA.png"
              alt="AXA"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/ARUP.png"
              alt="ARUP"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Cathay Pacific.png"
              alt="Cathay Pacific"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Centaline.png"
              alt="Centaline"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/China CITIC Bank.png"
              alt="China CITIC Bank"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/China Merchant Group Limited.png"
              alt="China Merchant Group Limited"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Chinese General Chamber of Commerce.png"
              alt="Chinese General Chamber of Commerce"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Citibank.png"
              alt="Citibank"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/CITIC Pacific.png"
              alt="CITIC Pacific"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/CTEG.png"
              alt="CTEG"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Dansinn.png"
              alt="Dansinn"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Ericsson Group.png"
              alt="Ericsson Group"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Esprit.png"
              alt="CSL"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/FHKCO.png"
              alt="FHKCO"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Food For Good.png"
              alt="Food For Good"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Four Seas Group.png"
              alt="Four Seas Group"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Friends of HK Association Ltd.png"
              alt="Friends of HK Association Ltd"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Gammon.png"
              alt="Gammon"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Gucci.png"
              alt="Gucci"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/HKAABU.jpg"
              alt="HKAABU"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/HKCEA.png"
              alt="HKCEA"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/HKECIC.png"
              alt="HKECIC"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/HKFFA.png"
              alt="HKFFA"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src="/img/clientLogos/HKT.png"
              alt="HKT"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Intertek.png"
              alt="Intertek"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Leighton Group.png"
              alt="Leighton Group"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/MGM.png"
              alt="MGM"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Mizuho.png"
              alt="Mizuho"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Nam Hoi Traders Association.png"
              alt="Nam Hoi Traders Association"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Nam Pak Hong Union.png"
              alt="Nam Pak Hong Union"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Nanhai Family.png"
              alt="Nanhai Family"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/PCCW.png"
              alt="PCCW"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Po Leung Kuk.png"
              alt="Po Leung Kuk"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Rotary 3450.png"
              alt="Rotary 3450"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Sasa.png"
              alt="Sasa"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Shimao Group.png"
              alt="Shimao Group"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/South China.png"
              alt="South China"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/img/clientLogos/Symphony Holdings Ltd.png"
              alt="Symphony Holdings Ltd"
              style={{ width: "100%", padding: "20px 20px" }}
            />
          </div>
        </Slider>
      </div>
    )
  }
}
