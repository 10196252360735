import React from "react"

import wordFlipStyles from "./wordFlip.module.scss"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Company = () => {
  return (
    <div id="company">
      <Container>
        <div className="sectionHeader">
          <Row noGutters>
            <Col>
              <img
                src="/img/MillionProductionLogo.svg"
                alt="Million Production & Promotion Co."
                style={{ maxWidth: "80vw", paddingBottom: "20px" }}
              />
            </Col>
          </Row>
        </div>
        <div className="sectionSubHeader">
          <Row noGutters>
            <Col>
              <div>
                <h3>
                  富高娱乐制作公司 <br />
                  提供一站式活动策划和制作服务
                </h3>
              </div>
            </Col>
          </Row>
        </div>
        <div className="sectionContent">
          <Row noGutters>
            <Col>
              <div>
                <h2 className={wordFlipStyles.sentence}>
                  合适的 &nbsp;
                  <div className={wordFlipStyles.verticalFlipC}>
                    <span>观众</span>
                    <span>信息</span>
                    <span>时机</span>
                  </div>
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Company
